import { axios } from "../connection/ConnectionHandler";
import { API } from "../constants/api";
import { keyBy } from "lodash";
import {
    ADD_NEW_DOCUMENT,
    DELETE_DOCUMENT,
    DOCUMENT_FORM_TOOGLE_LOADING,
    SET_DOCUMENT_LIST,
    SHOW_NOTIFICATION,
    UPDATE_DOCUMENT,
} from "../constants";
import { formatDocuments } from "./settingsActionsUtils";

/* DOCUMENT LIST */
export const fetchDocuments = () => {
    return async (dispatch) => {
        const response = await axios
            .get(API.documents.list)
            .then((response) => {
                const documents = formatDocuments(response.data);
                dispatch({
                    type: SET_DOCUMENT_LIST,
                    payload: keyBy(documents, "_id"),
                });
                return response.status;
            })
            .catch((err) => err);
        return response;
    };
};

// DOCUMENTS
export const createDocument = (newDocument) => {
    return async (dispatch) => {
        dispatch({ type: DOCUMENT_FORM_TOOGLE_LOADING });
        const response = await axios
            .post(API.documents.create, newDocument)
            .then((response) => {
                const document = formatDocuments(response.data);
                dispatch({ type: ADD_NEW_DOCUMENT, payload: document });
                dispatch({ type: DOCUMENT_FORM_TOOGLE_LOADING });
                dispatch({
                    type: SHOW_NOTIFICATION,
                    payload: {
                        show: true,
                        status: "success",
                        message: "Documento creado con éxito.",
                    },
                });
                return response && response.status;
            })
            .catch((err) => {
                dispatch({ type: DOCUMENT_FORM_TOOGLE_LOADING });
                return err;
            });
        return response;
    };
};

export const updateDocument = (updatedDocument) => {
    return async (dispatch) => {
        dispatch({ type: DOCUMENT_FORM_TOOGLE_LOADING });
        const response = await axios
            .put(`${API.documents.edit}${updatedDocument && updatedDocument._id}`, updatedDocument)
            .then((response) => {
                const document = formatDocuments(response.data);
                dispatch({ type: UPDATE_DOCUMENT, payload: document });
                dispatch({ type: DOCUMENT_FORM_TOOGLE_LOADING });
                dispatch({
                    type: SHOW_NOTIFICATION,
                    payload: {
                        show: true,
                        status: "info",
                        message: "Documento actualizado.",
                    },
                });
                return response && response.status;
            })
            .catch((err) => {
                dispatch({ type: DOCUMENT_FORM_TOOGLE_LOADING });
                return err;
            });
        return response;
    };
};

export const deleteDocuments = (Ids) => {
    return async (dispatch) => {
        dispatch({ type: DOCUMENT_FORM_TOOGLE_LOADING });
        const response = await axios
            .delete(`${API.documents.delete}`, { data: Ids })
            .then((response) => {
                dispatch({ type: DELETE_DOCUMENT, payload: Ids });
                dispatch({ type: DOCUMENT_FORM_TOOGLE_LOADING });
                dispatch({
                    type: SHOW_NOTIFICATION,
                    payload: {
                        show: true,
                        status: "info",
                        message: "Documento eliminado.",
                    },
                });
                return response && response.status;
            })
            .catch((err) => {
                dispatch({ type: DOCUMENT_FORM_TOOGLE_LOADING });
                return err;
            });
        return response;
    };
};
