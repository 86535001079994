import {
  ExclamationCircleOutlined,
  FilePdfOutlined,
  HomeOutlined,
  InsertRowAboveOutlined,
  LoginOutlined,
  LogoutOutlined,
  OneToOneOutlined,
  OrderedListOutlined,
  ProfileOutlined,
  ScheduleOutlined,
  ShoppingCartOutlined,
  SkinOutlined,
  TeamOutlined,
  ToolOutlined,
  UserOutlined,
} from "@ant-design/icons";
import React from "react";
import { ROLES } from "./permissions";

export const routes = {
  home: {
    key: "home",
    slug: "home",
    to: "/home",
    title: "Inicio",
    icon: <HomeOutlined />,
    showInMenu: true,
    showHeader: true,
    showSearch: true,
    action: null,
    role: [ROLES.admin.role, ROLES.user.role],
  },

  profile: {
    key: "profile",
    slug: "profile",
    to: "/profile",
    title: "Perfil",
    icon: <UserOutlined />,
    showInMenu: false,
    showHeader: true,
    showSearch: true,
    action: null,
    role: [ROLES.admin.role, ROLES.user.role],
  },

  users: {
    key: "users",
    slug: "users",
    to: "/users",
    title: "Usuarios",
    icon: <UserOutlined />,
    showInMenu: true,
    showHeader: true,
    showSearch: true,
    action: null,
    role: [ROLES.admin.role],
  },

  login: {
    key: "login",
    slug: "login",
    to: "/login",
    title: "LOGIN",
    icon: <LoginOutlined />,
    showInMenu: false,
    showHeader: false,
    showSearch: false,
    action: null,
    role: [ROLES.admin.role, ROLES.user.role],
  },

  staffs: {
    key: "staffs",
    slug: "staffs",
    to: "/staffs",
    title: "Personal",
    icon: <TeamOutlined />,
    showInMenu: true,
    showHeader: true,
    showSearch: true,
    action: null,
    role: [ROLES.admin.role],
  },
  personalClotheLists: {
    key: "personalClotheLists",
    slug: "personalClotheLists",
    to: "/personalClotheLists",
    title: "Prendas personal",
    icon: <OrderedListOutlined />,
    showInMenu: true,
    showHeader: true,
    showSearch: true,
    action: null,
    role: [ROLES.admin.role],
  },
  elements: {
    key: "elements",
    slug: "elements",
    to: "/elements",
    title: "Elementos",
    icon: <OneToOneOutlined />,
    showInMenu: true,
    showHeader: true,
    showSearch: true,
    action: null,
    role: [ROLES.admin.role],
  },
  rents: {
    key: "rents",
    slug: "rents",
    to: "/rents",
    title: "Alquileres",
    icon: <ScheduleOutlined />,
    showInMenu: true,
    showHeader: true,
    showSearch: true,
    action: null,
    role: [ROLES.admin.role],
  },
  typeClothes: {
    key: "typeClothes",
    slug: "typeClothes",
    to: "/typeClothes",
    title: "Tipo prendas",
    icon: <SkinOutlined />,
    showInMenu: true,
    showHeader: true,
    showSearch: true,
    action: null,
    role: [ROLES.admin.role],
  },
  clothes: {
    key: "clothes",
    slug: "clothes",
    to: "/clothes",
    title: "Tallas",
    icon: <InsertRowAboveOutlined />,
    showInMenu: true,
    showHeader: true,
    showSearch: true,
    action: null,
    role: [ROLES.admin.role],
  },
  documents: {
    key: "documents",
    slug: "documents",
    to: "/documents",
    title: "Documentos",
    icon: <FilePdfOutlined />,
    showInMenu: true,
    showHeader: true,
    showSearch: true,
    action: null,
    role: [ROLES.admin.role],
  },
incidences: {
    key: "incidences",
    slug: "incidences",
    to: "/incidences",
    title: "Incidencias",
    icon: <ExclamationCircleOutlined />,
    showInMenu: true,
    showHeader: true,
    showSearch: true,
    action: null,
    role: [ROLES.admin.role],
},
buyTypes: {
    key: "buyTypes",
    slug: "buyTypes",
    to: "/buyTypes",
    title: "Tipo de compras",
    icon: <ToolOutlined />,
    showInMenu: true,
    showHeader: true,
    showSearch: true,
    action: null,
    role: [ROLES.admin.role],
},
buys: {
    key: "buys",
    slug: "buys",
    to: "/buys",
    title: "Compras",
    icon: <ShoppingCartOutlined />,
    showInMenu: true,
    showHeader: true,
    showSearch: true,
    action: null,
    role: [ROLES.admin.role],
},
  logout: {
    key: "logout",
    slug: "logout",
    to: "/logout",
    title: "Cerrar Sesión",
    icon: <LogoutOutlined />,
    showInMenu: false,
    showHeader: false,
    showSearch: false,
    action: null,
    role: [ROLES.admin.role, ROLES.user.role],
  },
};
