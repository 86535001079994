import { axios } from "../connection/ConnectionHandler";
import { API } from "../constants/api";
import { keyBy } from "lodash";
import {
    ADD_NEW_CLOTHE,
    DELETE_CLOTHE,
    CLOTHE_FORM_TOOGLE_LOADING,
    SET_CLOTHE_LIST,
    SHOW_NOTIFICATION,
    UPDATE_CLOTHE,
} from "../constants";
import { formatClothes } from "./settingsActionsUtils";

/* CLOTHE LIST */
export const fetchClothes = () => {
    return async (dispatch, useState) => {
        const response = await axios
            .get(API.clothes.list)
            .then((response) => {
                const clothes = formatClothes(response.data, useState());
                dispatch({
                    type: SET_CLOTHE_LIST,
                    payload: keyBy(clothes, "_id"),
                });
                return response.status;
            })
            .catch((err) => console.log(err));
        return response;
    };
};

// CLOTHES
export const createClothe = (newClothe) => {
    return async (dispatch, useState) => {
        dispatch({ type: CLOTHE_FORM_TOOGLE_LOADING });
        const response = await axios
            .post(API.clothes.create, newClothe)
            .then((response) => {
                const clothe = formatClothes(response.data, useState());
                dispatch({ type: ADD_NEW_CLOTHE, payload: clothe });
                dispatch({ type: CLOTHE_FORM_TOOGLE_LOADING });
                dispatch({
                    type: SHOW_NOTIFICATION,
                    payload: {
                        show: true,
                        status: "success",
                        message: "Prenda creada con éxito.",
                    },
                });
                return response && response.status;
            })
            .catch((err) => {
                dispatch({ type: CLOTHE_FORM_TOOGLE_LOADING });
                return err;
            });
        return response;
    };
};

export const updateClothe = (updatedClothe) => {
    return async (dispatch, useState) => {
        dispatch({ type: CLOTHE_FORM_TOOGLE_LOADING });
        const response = await axios
            .put(`${API.clothes.edit}${updatedClothe && updatedClothe._id}`, updatedClothe)
            .then((response) => {
                const clothe = formatClothes(response.data, useState());
                dispatch({ type: UPDATE_CLOTHE, payload: clothe });
                dispatch({ type: CLOTHE_FORM_TOOGLE_LOADING });
                dispatch({
                    type: SHOW_NOTIFICATION,
                    payload: {
                        show: true,
                        status: "info",
                        message: "Prenda actualizado.",
                    },
                });
                return response && response.status;
            })
            .catch((err) => {
                dispatch({ type: CLOTHE_FORM_TOOGLE_LOADING });
                return err;
            });
        return response;
    };
};

export const deleteClothes = (Ids) => {
    return async (dispatch) => {
        dispatch({ type: CLOTHE_FORM_TOOGLE_LOADING });
        const response = await axios
            .delete(`${API.clothes.delete}`, { data: Ids })
            .then((response) => {
                dispatch({ type: DELETE_CLOTHE, payload: Ids });
                dispatch({ type: CLOTHE_FORM_TOOGLE_LOADING });
                dispatch({
                    type: SHOW_NOTIFICATION,
                    payload: {
                        show: true,
                        status: "info",
                        message: "Prenda eliminada.",
                    },
                });
                return response && response.status;
            })
            .catch((err) => {
                dispatch({ type: CLOTHE_FORM_TOOGLE_LOADING });
                return err;
            });
        return response;
    };
};
