import { axios } from "../connection/ConnectionHandler";
import { API } from "../constants/api";
import { keyBy } from "lodash";
import {
    ADD_NEW_TYPECLOTHE,
    DELETE_TYPECLOTHE,
    TYPECLOTHE_FORM_TOOGLE_LOADING,
    SET_TYPECLOTHE_LIST,
    SHOW_NOTIFICATION,
    UPDATE_TYPECLOTHE,
} from "../constants";
import { formatTypeClothes } from "./settingsActionsUtils";

/* TYPECLOTHE LIST */
export const fetchTypeClothes = () => {
    return async (dispatch) => {
        const response = await axios
            .get(API.typeClothes.list)
            .then((response) => {
                const typeClothes = formatTypeClothes(response.data);
                dispatch({
                    type: SET_TYPECLOTHE_LIST,
                    payload: keyBy(typeClothes, "_id"),
                });
                return response.status;
            })
            .catch((err) => err);
        return response;
    };
};

// TYPECLOTHES
export const createTypeClothe = (newTypeClothe) => {
    return async (dispatch) => {
        dispatch({ type: TYPECLOTHE_FORM_TOOGLE_LOADING });
        const response = await axios
            .post(API.typeClothes.create, newTypeClothe)
            .then((response) => {
                const typeClothe = formatTypeClothes(response.data);
                dispatch({ type: ADD_NEW_TYPECLOTHE, payload: typeClothe });
                dispatch({ type: TYPECLOTHE_FORM_TOOGLE_LOADING });
                dispatch({
                    type: SHOW_NOTIFICATION,
                    payload: {
                        show: true,
                        status: "success",
                        message: "Tipo prenda creado con éxito.",
                    },
                });
                return response && response.status;
            })
            .catch((err) => {
                dispatch({ type: TYPECLOTHE_FORM_TOOGLE_LOADING });
                return err;
            });
        return response;
    };
};

export const updateTypeClothe = (updatedTypeClothe) => {
    return async (dispatch) => {
        dispatch({ type: TYPECLOTHE_FORM_TOOGLE_LOADING });
        const response = await axios
            .put(`${API.typeClothes.edit}${updatedTypeClothe && updatedTypeClothe._id}`, updatedTypeClothe)
            .then((response) => {
                const typeClothe = formatTypeClothes(response.data);
                dispatch({ type: UPDATE_TYPECLOTHE, payload: typeClothe });
                dispatch({ type: TYPECLOTHE_FORM_TOOGLE_LOADING });
                dispatch({
                    type: SHOW_NOTIFICATION,
                    payload: {
                        show: true,
                        status: "info",
                        message: "Tipo prenda actualizado.",
                    },
                });
                return response && response.status;
            })
            .catch((err) => {
                dispatch({ type: TYPECLOTHE_FORM_TOOGLE_LOADING });
                return err;
            });
        return response;
    };
};

export const deleteTypeClothes = (Ids) => {
    return async (dispatch) => {
        dispatch({ type: TYPECLOTHE_FORM_TOOGLE_LOADING });
        const response = await axios
            .delete(`${API.typeClothes.delete}`, { data: Ids })
            .then((response) => {
                dispatch({ type: DELETE_TYPECLOTHE, payload: Ids });
                dispatch({ type: TYPECLOTHE_FORM_TOOGLE_LOADING });
                dispatch({
                    type: SHOW_NOTIFICATION,
                    payload: {
                        show: true,
                        status: "info",
                        message: "Tipo prenda eliminado.",
                    },
                });
                return response && response.status;
            })
            .catch((err) => {
                dispatch({ type: TYPECLOTHE_FORM_TOOGLE_LOADING });
                return err;
            });
        return response;
    };
};
