export const API = {
	buys: {
    create: `/buy`,
    edit: `/buy/`,
    delete: `/buy/`,
    list: `/buy`,
  },
	buyTypes: {
    create: `/buyType`,
    edit: `/buyType/`,
    delete: `/buyType/`,
    list: `/buyType`,
  },
	incidences: {
    create: `/incidence`,
    edit: `/incidence/`,
    delete: `/incidence/`,
    list: `/incidence`,
  },
	documents: {
    create: `/document`,
    edit: `/document/`,
    delete: `/document/`,
    list: `/document`,
  },
	personalClotheLists: {
    create: `/personalClotheList`,
    edit: `/personalClotheList/`,
    delete: `/personalClotheList/`,
    list: `/personalClotheList`,
  },
	typeClothes: {
    create: `/typeClothe`,
    edit: `/typeClothe/`,
    delete: `/typeClothe/`,
    list: `/typeClothe`,
  },
	rents: {
    create: `/rent`,
    edit: `/rent/`,
    delete: `/rent/`,
    list: `/rent`,
  },
	clothes: {
    create: `/clothe`,
    edit: `/clothe/`,
    delete: `/clothe/`,
    list: `/clothe`,
  },
	elements: {
    create: `/element`,
    edit: `/element/`,
    delete: `/element/`,
    list: `/element`,
  },
	staffs: {
    create: `/staff`,
    edit: `/staff/`,
    delete: `/staff/`,
    list: `/staff`,
  },
  auth: {
    login: `/user/login`,
    check2FA: `/user/check2FA`,
  },
  users: {
    profile: `/user/profile`,
    create: `/user/register/`,
    edit: `/user/`,
    delete: `/user/`,
    list: `/user`,
  },
  alerts: {
    create: `/alert`,
    edit: `/alert/`,
    delete: `/alert/`,
    list: `/alert`,
  },
};
