import { createSelector } from "reselect";
import moment from "moment";
import "moment/locale/es";
import { ROLES_RAW } from "../../constants/permissions";
moment().locale("es");

/* APP */
export const isUserAuthorized = createSelector(
  (state) => state.authorization,
  (authorization) => authorization
);
export const getCurrentRoute = createSelector(
  (state) => state.currentRoute,
  (currentRoute) => currentRoute
);
export const getCurrentRouteTitle = createSelector(
  getCurrentRoute,
  (route) => route.title
);
export const getSideMenuState = createSelector(
  (state) => state.app,
  (app) => app.isCollapsedSideMenu
);
export const getCurrentTheme = createSelector(
  (state) => state.currentTheme,
  (currentTheme) => currentTheme
);

/* PROFILE */
export const getUserProfile = createSelector(
  (state) => state.profile,
  (profile) => profile
);
export const currentUserId = createSelector(
  (state) => state.profile,
  (profile) => profile && profile.id
);
export const getCurrentUserRole = createSelector(getUserProfile, (profile) =>
  !profile ? null : (ROLES_RAW.includes(profile.role) ? profile.role : null)
);

/* NOTIFICATIONS STATE */
export const getNotificationsState = createSelector(
  (state) => state.notifications,
  (notifications) => notifications
);

/* USERS */
export const getUsers = createSelector(
  (state) => state.users,
  (users) => users
);
export const getUsersForm = createSelector(
  (state) => state.usersForm,
  (form) => form
);

/* ALERT */
export const getAlerts = createSelector(
  (state) => state.alerts,
  (alerts) => alerts
);
export const getAlertsForm = createSelector(
  (state) => state.alertsForm,
  (form) => form
);

/* STAFFS */
export const getStaffs = createSelector(
    (state) => state.staffs,
    (staffs) => staffs
);
export const getStaffsForm = createSelector(
    (state) => state.staffsForm,
    (form) => form
);

/* ELEMENTS */
export const getElements = createSelector(
    (state) => state.elements,
    (elements) => elements
);
export const getElementsForm = createSelector(
    (state) => state.elementsForm,
    (form) => form
);

/* CLOTHES */
export const getClothes = createSelector(
    (state) => state.clothes,
    (clothes) => clothes
);
export const getClothesForm = createSelector(
    (state) => state.clothesForm,
    (form) => form
);

/* RENTS */
export const getRents = createSelector(
    (state) => state.rents,
    (rents) => rents
);
export const getRentsForm = createSelector(
    (state) => state.rentsForm,
    (form) => form
);

/* TYPECLOTHES */
export const getTypeClothes = createSelector(
    (state) => state.typeClothes,
    (typeClothes) => typeClothes
);
export const getTypeClothesForm = createSelector(
    (state) => state.typeClothesForm,
    (form) => form
);

/* PERSONALCLOTHELISTS */
export const getPersonalClotheLists = createSelector(
    (state) => state.personalClotheLists,
    (personalClotheLists) => personalClotheLists
);
export const getPersonalClotheListsForm = createSelector(
    (state) => state.personalClotheListsForm,
    (form) => form
);

/* DOCUMENTS */
export const getDocuments = createSelector(
    (state) => state.documents,
    (documents) => documents
);
export const getDocumentsForm = createSelector(
    (state) => state.documentsForm,
    (form) => form
);

/* INCIDENCES */
export const getIncidences = createSelector(
    (state) => state.incidences,
    (incidences) => incidences
);
export const getIncidencesForm = createSelector(
    (state) => state.incidencesForm,
    (form) => form
);

/* BUYTYPES */
export const getBuyTypes = createSelector(
    (state) => state.buyTypes,
    (buyTypes) => buyTypes
);
export const getBuyTypesForm = createSelector(
    (state) => state.buyTypesForm,
    (form) => form
);

/* BUYS */
export const getBuys = createSelector(
    (state) => state.buys,
    (buys) => buys
);
export const getBuysForm = createSelector(
    (state) => state.buysForm,
    (form) => form
);
