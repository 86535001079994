import moment from "moment";
import { isArray, isObject } from "lodash";

export const formatBuys = (data) => {
  if (!data) {
    return [];
  }
  if (isArray(data)) {
    return data.map((data) => ({
      ...data,
      label: `${data.name}`,
      value: data._id,
      dateOfPurchase: data.dateOfPurchase && moment(data.dateOfPurchase),
    }));
  }
  if (isObject(data)) {
    return {
      ...data,
      value: data._id,
      label: `${data.name}`,
      dateOfPurchase: data.dateOfPurchase && moment(data.dateOfPurchase),
    };
  }
};
export const formatBuyTypes = (data) => {
  if (!data) {
    return [];
  }
  if (isArray(data)) {
    return data.map((data) => ({
      ...data,
      label: `${data.name}`,
      value: data._id,
    }));
  }
  if (isObject(data)) {
    return {
      ...data,
      value: data._id,
      label: `${data.name}`,
    };
  }
};
export const formatIncidences = (data) => {
  if (!data) {
    return [];
  }
  if (isArray(data)) {
    return data.map((data) => ({
      ...data,
      label: `${data.name}`,
      value: data._id,
      start: data.start && moment(data.start),
      predictedend: data.predictedend && moment(data.predictedend),
      end: data.end && moment(data.end),
    }));
  }
  if (isObject(data)) {
    return {
      ...data,
      value: data._id,
      label: `${data.name}`,
      start: data.start && moment(data.start),
      predictedend: data.predictedend && moment(data.predictedend),
      end: data.end && moment(data.end),
    };
  }
};
export const formatDocuments = (data) => {
  if (!data) {
    return [];
  }
  if (isArray(data)) {
    return data.map((data) => ({
      ...data,
      label: `${data.name}`,
      value: data._id,
    }));
  }
  if (isObject(data)) {
    return {
      ...data,
      value: data._id,
      label: `${data.name}`,
    };
  }
};
export const formatPersonalClotheLists = (data) => {
  if (!data) {
    return [];
  }
  if (isArray(data)) {
    return data.map((data) => ({
      ...data,
      label: `${data.name}`,
      value: data._id,
      devolutionDate: data.devolutionDate && moment(data.devolutionDate),
      deliveryDate: data.deliveryDate && moment(data.deliveryDate),
    }));
  }
  if (isObject(data)) {
    return {
      ...data,
      value: data._id,
      label: `${data.name}`,
      devolutionDate: data.devolutionDate && moment(data.devolutionDate),
      deliveryDate: data.deliveryDate && moment(data.deliveryDate),
    };
  }
};
export const formatTypeClothes = (data) => {
  if (!data) {
    return [];
  }
  if (isArray(data)) {
    return data.map((data) => ({
      ...data,
      label: `${data.clotheName}`,
      value: data._id,
    }));
  }
  if (isObject(data)) {
    return {
      ...data,
      value: data._id,
      label: `${data.clotheName}`,
    };
  }
};
export const formatRents = (data) => {
  if (!data) {
    return [];
  }
  if (isArray(data)) {
    return data.map((data) => ({
      ...data,
      label: `${data.name}`,
      value: data._id,
      outDate: data.outDate && moment(data.outDate),
      inDate: data.inDate && moment(data.inDate),
    }));
  }
  if (isObject(data)) {
    return {
      ...data,
      value: data._id,
      label: `${data.name}`,
      outDate: data.outDate && moment(data.outDate),
      inDate: data.inDate && moment(data.inDate),
    };
  }
};
export const formatClothes = (data, state) => {
  if (!data) {
    return [];
  }
  if (isArray(data)) {
    return data.map((data) => ({
      ...data,
      label: `${state.typeClothes[data.clotheName]?.clotheName} ${data.size}`,
      value: data._id,
    }));
  }
  if (isObject(data)) {
    return {
      ...data,
      value: data._id,
      label: `${state.typeClothes[data.clotheName]?.clotheName} ${data.size}`,
    };
  }
};
export const formatElements = (data) => {
  if (!data) {
    return [];
  }
  if (isArray(data)) {
    return data.map((data) => ({
      ...data,
      label: `${data.elementType}`,
      value: data._id,
    }));
  }
  if (isObject(data)) {
    return {
      ...data,
      value: data._id,
      label: `${data.elementType}`,
    };
  }
};
export const formatStaffs = (data) => {
  if (!data) {
    return [];
  }
  if (isArray(data)) {
    return data.map((data) => ({
      ...data,
      label: `${data.name} ${data.surname}`,
      value: data._id,
    }));
  }
  if (isObject(data)) {
    return {
      ...data,
      value: data._id,
      label: `${data.name} ${data.surname}`,
    };
  }
};
export const formatUsers = (data) => {
  if (!data) {
    return [];
  }
  if (isArray(data)) {
    return data.map((data) => ({
      ...data,
      label: `${data.name} ${data.surname}`,
      value: data._id
    }));
  }
  if (isObject(data)) {
    return {
      ...data,
      value: data._id,
      label: `${data.name} ${data.surname}`
    };
  }
};

export const formatAlerts = (data) => {
  if (!data) {
    return [];
  }
  if (isArray(data)) {
    return data.map((data) => ({
      ...data,
      label: `${data.title}`,
      value: data._id,
    }));
  }
  if (isObject(data)) {
    return {
      ...data,
      value: data._id,
      label: `${data.title}`,
    };
  }
};
