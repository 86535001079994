import { axios } from "../connection/ConnectionHandler";
import { API } from "../constants/api";
import { keyBy } from "lodash";
import {
    ADD_NEW_STAFF,
    DELETE_STAFF,
    STAFF_FORM_TOOGLE_LOADING,
    SET_STAFF_LIST,
    SHOW_NOTIFICATION,
    UPDATE_STAFF,
} from "../constants";
import { formatStaffs } from "./settingsActionsUtils";

/* STAFF LIST */
export const fetchStaffs = () => {
    return async (dispatch) => {
        const response = await axios
            .get(API.staffs.list)
            .then((response) => {
                const staffs = formatStaffs(response.data);
                dispatch({
                    type: SET_STAFF_LIST,
                    payload: keyBy(staffs, "_id"),
                });
                return response.status;
            })
            .catch((err) => err);
        return response;
    };
};

// STAFFS
export const createStaff = (newStaff) => {
    return async (dispatch) => {
        dispatch({ type: STAFF_FORM_TOOGLE_LOADING });
        const response = await axios
            .post(API.staffs.create, newStaff)
            .then((response) => {
                const staff = formatStaffs(response.data);
                dispatch({ type: ADD_NEW_STAFF, payload: staff });
                dispatch({ type: STAFF_FORM_TOOGLE_LOADING });
                dispatch({
                    type: SHOW_NOTIFICATION,
                    payload: {
                        show: true,
                        status: "success",
                        message: "Personal creado con éxito.",
                    },
                });
                return response && response.status;
            })
            .catch((err) => {
                dispatch({ type: STAFF_FORM_TOOGLE_LOADING });
                return err;
            });
        return response;
    };
};

export const updateStaff = (updatedStaff) => {
    return async (dispatch) => {
        dispatch({ type: STAFF_FORM_TOOGLE_LOADING });
        const response = await axios
            .put(`${API.staffs.edit}${updatedStaff && updatedStaff._id}`, updatedStaff)
            .then((response) => {
                const staff = formatStaffs(response.data);
                dispatch({ type: UPDATE_STAFF, payload: staff });
                dispatch({ type: STAFF_FORM_TOOGLE_LOADING });
                dispatch({
                    type: SHOW_NOTIFICATION,
                    payload: {
                        show: true,
                        status: "info",
                        message: "Personal actualizado.",
                    },
                });
                return response && response.status;
            })
            .catch((err) => {
                dispatch({ type: STAFF_FORM_TOOGLE_LOADING });
                return err;
            });
        return response;
    };
};

export const deleteStaffs = (Ids) => {
    return async (dispatch) => {
        dispatch({ type: STAFF_FORM_TOOGLE_LOADING });
        const response = await axios
            .delete(`${API.staffs.delete}`, { data: Ids })
            .then((response) => {
                dispatch({ type: DELETE_STAFF, payload: Ids });
                dispatch({ type: STAFF_FORM_TOOGLE_LOADING });
                dispatch({
                    type: SHOW_NOTIFICATION,
                    payload: {
                        show: true,
                        status: "info",
                        message: "Personal eliminado.",
                    },
                });
                return response && response.status;
            })
            .catch((err) => {
                dispatch({ type: STAFF_FORM_TOOGLE_LOADING });
                return err;
            });
        return response;
    };
};
