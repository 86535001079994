import { handleActions, handleAction } from "redux-actions";
import {
  AUTH,
  SET_PROFILE,
  SET_CURRENT_ROUTE,
  LOGOUT,
  SHOW_NOTIFICATION,
  CLOSE_NOTIFICATION,
  SET_SIDE_MENU_STATE,
  OPEN_USERS_FORM,
  CLOSE_USERS_FORM,
  EDIT_SELECTED_USER,
  USERS_FORM_TOOGLE_LOADING,
  SET_USER_LIST,
  ADD_NEW_USER,
  UPDATE_USER,
  DELETE_USER,
  OPEN_ALERT_FORM,
  CLOSE_ALERT_FORM,
  EDIT_SELECTED_ALERT,
  ALERT_FORM_TOOGLE_LOADING,
  SET_ALERT_LIST,
  ADD_NEW_ALERT,
  UPDATE_ALERT,
  DELETE_ALERT,
  THEME,
  ADD_NEW_STAFF,
  SET_STAFF_LIST,
  UPDATE_STAFF,
  DELETE_STAFF,
  OPEN_STAFF_FORM,
  CLOSE_STAFF_FORM,
  EDIT_SELECTED_STAFF,
  STAFF_FORM_TOOGLE_LOADING,
  ADD_NEW_ELEMENT,
  SET_ELEMENT_LIST,
  UPDATE_ELEMENT,
  DELETE_ELEMENT,
  OPEN_ELEMENT_FORM,
  CLOSE_ELEMENT_FORM,
  EDIT_SELECTED_ELEMENT,
  ELEMENT_FORM_TOOGLE_LOADING,
  ADD_NEW_CLOTHE,
  SET_CLOTHE_LIST,
  UPDATE_CLOTHE,
  DELETE_CLOTHE,
  OPEN_CLOTHE_FORM,
  CLOSE_CLOTHE_FORM,
  EDIT_SELECTED_CLOTHE,
  CLOTHE_FORM_TOOGLE_LOADING,
  ADD_NEW_RENT,
  SET_RENT_LIST,
  UPDATE_RENT,
  DELETE_RENT,
  OPEN_RENT_FORM,
  CLOSE_RENT_FORM,
  EDIT_SELECTED_RENT,
  RENT_FORM_TOOGLE_LOADING,
  ADD_NEW_TYPECLOTHE,
  SET_TYPECLOTHE_LIST,
  UPDATE_TYPECLOTHE,
  DELETE_TYPECLOTHE,
  OPEN_TYPECLOTHE_FORM,
  CLOSE_TYPECLOTHE_FORM,
  EDIT_SELECTED_TYPECLOTHE,
  TYPECLOTHE_FORM_TOOGLE_LOADING,
  ADD_NEW_PERSONALCLOTHELIST,
  SET_PERSONALCLOTHELIST_LIST,
  UPDATE_PERSONALCLOTHELIST,
  DELETE_PERSONALCLOTHELIST,
  OPEN_PERSONALCLOTHELIST_FORM,
  CLOSE_PERSONALCLOTHELIST_FORM,
  EDIT_SELECTED_PERSONALCLOTHELIST,
  PERSONALCLOTHELIST_FORM_TOOGLE_LOADING,
  ADD_NEW_DOCUMENT,
  SET_DOCUMENT_LIST,
  UPDATE_DOCUMENT,
  DELETE_DOCUMENT,
  OPEN_DOCUMENT_FORM,
  CLOSE_DOCUMENT_FORM,
  EDIT_SELECTED_DOCUMENT,
  DOCUMENT_FORM_TOOGLE_LOADING,
  ADD_NEW_INCIDENCE,
  SET_INCIDENCE_LIST,
  UPDATE_INCIDENCE,
  DELETE_INCIDENCE,
  OPEN_INCIDENCE_FORM,
  CLOSE_INCIDENCE_FORM,
  EDIT_SELECTED_INCIDENCE,
  INCIDENCE_FORM_TOOGLE_LOADING,
  ADD_NEW_BUYTYPE,
  SET_BUYTYPE_LIST,
  UPDATE_BUYTYPE,
  DELETE_BUYTYPE,
  OPEN_BUYTYPE_FORM,
  CLOSE_BUYTYPE_FORM,
  EDIT_SELECTED_BUYTYPE,
  BUYTYPE_FORM_TOOGLE_LOADING,
  ADD_NEW_BUY,
  SET_BUY_LIST,
  UPDATE_BUY,
  DELETE_BUY,
  OPEN_BUY_FORM,
  CLOSE_BUY_FORM,
  EDIT_SELECTED_BUY,
  BUY_FORM_TOOGLE_LOADING,
} from "./../../constants";

/* BASIC */
export const authorization = handleActions(
  {
    [AUTH]: (state, action) => action.payload,
    [LOGOUT]: () => ({ auth: false }),
  },
  null
);

/* PROFILE */
export const profile = handleActions(
  {
    [SET_PROFILE]: (state, action) => action.payload,
  },
  null
);

/* APP */
export const currentRoute = handleAction(
  SET_CURRENT_ROUTE,
  (state, action) => action.payload,
  {}
);

export const notifications = handleActions(
  {
    [SHOW_NOTIFICATION]: (state, action) => action.payload,
    [CLOSE_NOTIFICATION]: (state, action) => ({
      show: false,
      status: null,
      message: null,
    }),
  },
  { show: false, status: null, message: null }
);

/* APP */
export const app = handleActions(
  {
    [SET_SIDE_MENU_STATE]: (state, action) => {
      return { ...state, isCollapsedSideMenu: action.payload };
    },
  },
  { isCollapsedSideMenu: true }
);

export const currentTheme = handleAction(
  THEME,
  (state, action) => action.payload,
  false
);

export const users = handleActions(
  {
    [SET_USER_LIST]: (state, action) => action.payload,
    [ADD_NEW_USER]: (state, action) => {
      const newState = {
        ...state,
        [action.payload.value]: action.payload,
      };
      return newState;
    },
    [UPDATE_USER]: (state, action) => {
      const newState = {
        ...state,
        [action.payload.value]: action.payload,
      };
      return newState;
    },
    [DELETE_USER]: (state, action) => {
      action.payload.forEach((number) => {
        delete state[number];
      });
      return { ...state };
    },
  },
  null
);

export const usersForm = handleActions(
  {
    [OPEN_USERS_FORM]: (state) => ({
      show: !state.show,
      data: null,
      loading: false,
    }),
    [CLOSE_USERS_FORM]: () => ({ show: false, data: null, loading: false }),
    [EDIT_SELECTED_USER]: (s, action) => ({
      show: true,
      data: action.payload,
      loading: false,
    }),
    [USERS_FORM_TOOGLE_LOADING]: (state) => ({
      ...state,
      loading: !state.loading,
    }),
  },
  { show: false, data: null, loading: false }
);

export const alerts = handleActions(
  {
    [SET_ALERT_LIST]: (state, action) => action.payload,
    [ADD_NEW_ALERT]: (state, action) => {
      const newState = {
        ...state,
        [action.payload.value]: action.payload,
      };
      return newState;
    },
    [UPDATE_ALERT]: (state, action) => {
      const newState = {
        ...state,
        [action.payload.value]: action.payload,
      };
      return newState;
    },
    [DELETE_ALERT]: (state, action) => {
      action.payload.forEach((number) => {
        delete state[number];
      });
      return { ...state };
    },
  },
  null
);

export const alertsForm = handleActions(
  {
    [OPEN_ALERT_FORM]: (state) => ({
      show: !state.show,
      data: null,
      loading: false,
    }),
    [CLOSE_ALERT_FORM]: () => ({ show: false, data: null, loading: false }),
    [EDIT_SELECTED_ALERT]: (s, action) => ({
      show: true,
      data: action.payload,
      loading: false,
    }),
    [ALERT_FORM_TOOGLE_LOADING]: (state) => ({
      ...state,
      loading: !state.loading,
    }),
  },
  { show: false, data: null, loading: false }
);

// STAFFS
export const staffs = handleActions(
    {
        [SET_STAFF_LIST]: (state, action) => action.payload,
        [ADD_NEW_STAFF]: (state, action) => {
            const newState = {
                ...state,
                [action.payload.value]: action.payload,
            };
            return newState;
        },
        [UPDATE_STAFF]: (state, action) => {
            const newState = {
                ...state,
                [action.payload.value]: action.payload,
            };
            return newState;
        },
        [DELETE_STAFF]: (state, action) => {
            action.payload.forEach((number) => {
                delete state[number];
            });
            return { ...state };
        },
    },
    null
);

export const staffsForm = handleActions(
    {
        [OPEN_STAFF_FORM]: (state) => ({
            show: !state.show,
            data: null,
            loading: false,
        }),
        [CLOSE_STAFF_FORM]: () => ({
            show: false,
            data: null,
            loading: false,
        }),
        [EDIT_SELECTED_STAFF]: (s, action) => ({
            show: true,
            data: action.payload,
            loading: false,
        }),
        [STAFF_FORM_TOOGLE_LOADING]: (state) => ({
            ...state,
            loading: !state.loading,
        }),
    },
    { show: false, data: null, loading: false }
);

// ELEMENTS
export const elements = handleActions(
    {
        [SET_ELEMENT_LIST]: (state, action) => action.payload,
        [ADD_NEW_ELEMENT]: (state, action) => {
            const newState = {
                ...state,
                [action.payload.value]: action.payload,
            };
            return newState;
        },
        [UPDATE_ELEMENT]: (state, action) => {
            const newState = {
                ...state,
                [action.payload.value]: action.payload,
            };
            return newState;
        },
        [DELETE_ELEMENT]: (state, action) => {
            action.payload.forEach((number) => {
                delete state[number];
            });
            return { ...state };
        },
    },
    null
);

export const elementsForm = handleActions(
    {
        [OPEN_ELEMENT_FORM]: (state) => ({
            show: !state.show,
            data: null,
            loading: false,
        }),
        [CLOSE_ELEMENT_FORM]: () => ({
            show: false,
            data: null,
            loading: false,
        }),
        [EDIT_SELECTED_ELEMENT]: (s, action) => ({
            show: true,
            data: action.payload,
            loading: false,
        }),
        [ELEMENT_FORM_TOOGLE_LOADING]: (state) => ({
            ...state,
            loading: !state.loading,
        }),
    },
    { show: false, data: null, loading: false }
);

// CLOTHES
export const clothes = handleActions(
    {
        [SET_CLOTHE_LIST]: (state, action) => action.payload,
        [ADD_NEW_CLOTHE]: (state, action) => {
            const newState = {
                ...state,
                [action.payload.value]: action.payload,
            };
            return newState;
        },
        [UPDATE_CLOTHE]: (state, action) => {
            const newState = {
                ...state,
                [action.payload.value]: action.payload,
            };
            return newState;
        },
        [DELETE_CLOTHE]: (state, action) => {
            action.payload.forEach((number) => {
                delete state[number];
            });
            return { ...state };
        },
    },
    null
);

export const clothesForm = handleActions(
    {
        [OPEN_CLOTHE_FORM]: (state) => ({
            show: !state.show,
            data: null,
            loading: false,
        }),
        [CLOSE_CLOTHE_FORM]: () => ({
            show: false,
            data: null,
            loading: false,
        }),
        [EDIT_SELECTED_CLOTHE]: (s, action) => ({
            show: true,
            data: action.payload,
            loading: false,
        }),
        [CLOTHE_FORM_TOOGLE_LOADING]: (state) => ({
            ...state,
            loading: !state.loading,
        }),
    },
    { show: false, data: null, loading: false }
);

// RENTS
export const rents = handleActions(
    {
        [SET_RENT_LIST]: (state, action) => action.payload,
        [ADD_NEW_RENT]: (state, action) => {
            const newState = {
                ...state,
                [action.payload.value]: action.payload,
            };
            return newState;
        },
        [UPDATE_RENT]: (state, action) => {
            const newState = {
                ...state,
                [action.payload.value]: action.payload,
            };
            return newState;
        },
        [DELETE_RENT]: (state, action) => {
            action.payload.forEach((number) => {
                delete state[number];
            });
            return { ...state };
        },
    },
    null
);

export const rentsForm = handleActions(
    {
        [OPEN_RENT_FORM]: (state) => ({
            show: !state.show,
            data: null,
            loading: false,
        }),
        [CLOSE_RENT_FORM]: () => ({
            show: false,
            data: null,
            loading: false,
        }),
        [EDIT_SELECTED_RENT]: (s, action) => ({
            show: true,
            data: action.payload,
            loading: false,
        }),
        [RENT_FORM_TOOGLE_LOADING]: (state) => ({
            ...state,
            loading: !state.loading,
        }),
    },
    { show: false, data: null, loading: false }
);

// TYPECLOTHES
export const typeClothes = handleActions(
    {
        [SET_TYPECLOTHE_LIST]: (state, action) => action.payload,
        [ADD_NEW_TYPECLOTHE]: (state, action) => {
            const newState = {
                ...state,
                [action.payload.value]: action.payload,
            };
            return newState;
        },
        [UPDATE_TYPECLOTHE]: (state, action) => {
            const newState = {
                ...state,
                [action.payload.value]: action.payload,
            };
            return newState;
        },
        [DELETE_TYPECLOTHE]: (state, action) => {
            action.payload.forEach((number) => {
                delete state[number];
            });
            return { ...state };
        },
    },
    null
);

export const typeClothesForm = handleActions(
    {
        [OPEN_TYPECLOTHE_FORM]: (state) => ({
            show: !state.show,
            data: null,
            loading: false,
        }),
        [CLOSE_TYPECLOTHE_FORM]: () => ({
            show: false,
            data: null,
            loading: false,
        }),
        [EDIT_SELECTED_TYPECLOTHE]: (s, action) => ({
            show: true,
            data: action.payload,
            loading: false,
        }),
        [TYPECLOTHE_FORM_TOOGLE_LOADING]: (state) => ({
            ...state,
            loading: !state.loading,
        }),
    },
    { show: false, data: null, loading: false }
);

// PERSONALCLOTHELISTS
export const personalClotheLists = handleActions(
    {
        [SET_PERSONALCLOTHELIST_LIST]: (state, action) => action.payload,
        [ADD_NEW_PERSONALCLOTHELIST]: (state, action) => {
            const newState = {
                ...state,
                [action.payload.value]: action.payload,
            };
            return newState;
        },
        [UPDATE_PERSONALCLOTHELIST]: (state, action) => {
            const newState = {
                ...state,
                [action.payload.value]: action.payload,
            };
            return newState;
        },
        [DELETE_PERSONALCLOTHELIST]: (state, action) => {
            action.payload.forEach((number) => {
                delete state[number];
            });
            return { ...state };
        },
    },
    null
);

export const personalClotheListsForm = handleActions(
    {
        [OPEN_PERSONALCLOTHELIST_FORM]: (state) => ({
            show: !state.show,
            data: null,
            loading: false,
        }),
        [CLOSE_PERSONALCLOTHELIST_FORM]: () => ({
            show: false,
            data: null,
            loading: false,
        }),
        [EDIT_SELECTED_PERSONALCLOTHELIST]: (s, action) => ({
            show: true,
            data: action.payload,
            loading: false,
        }),
        [PERSONALCLOTHELIST_FORM_TOOGLE_LOADING]: (state) => ({
            ...state,
            loading: !state.loading,
        }),
    },
    { show: false, data: null, loading: false }
);

// DOCUMENTS
export const documents = handleActions(
    {
        [SET_DOCUMENT_LIST]: (state, action) => action.payload,
        [ADD_NEW_DOCUMENT]: (state, action) => {
            const newState = {
                ...state,
                [action.payload.value]: action.payload,
            };
            return newState;
        },
        [UPDATE_DOCUMENT]: (state, action) => {
            const newState = {
                ...state,
                [action.payload.value]: action.payload,
            };
            return newState;
        },
        [DELETE_DOCUMENT]: (state, action) => {
            action.payload.forEach((number) => {
                delete state[number];
            });
            return { ...state };
        },
    },
    null
);

export const documentsForm = handleActions(
    {
        [OPEN_DOCUMENT_FORM]: (state) => ({
            show: !state.show,
            data: null,
            loading: false,
        }),
        [CLOSE_DOCUMENT_FORM]: () => ({
            show: false,
            data: null,
            loading: false,
        }),
        [EDIT_SELECTED_DOCUMENT]: (s, action) => ({
            show: true,
            data: action.payload,
            loading: false,
        }),
        [DOCUMENT_FORM_TOOGLE_LOADING]: (state) => ({
            ...state,
            loading: !state.loading,
        }),
    },
    { show: false, data: null, loading: false }
);

// INCIDENCES
export const incidences = handleActions(
    {
        [SET_INCIDENCE_LIST]: (state, action) => action.payload,
        [ADD_NEW_INCIDENCE]: (state, action) => {
            const newState = {
                ...state,
                [action.payload.value]: action.payload,
            };
            return newState;
        },
        [UPDATE_INCIDENCE]: (state, action) => {
            const newState = {
                ...state,
                [action.payload.value]: action.payload,
            };
            return newState;
        },
        [DELETE_INCIDENCE]: (state, action) => {
            action.payload.forEach((number) => {
                delete state[number];
            });
            return { ...state };
        },
    },
    null
);

export const incidencesForm = handleActions(
    {
        [OPEN_INCIDENCE_FORM]: (state) => ({
            show: !state.show,
            data: null,
            loading: false,
        }),
        [CLOSE_INCIDENCE_FORM]: () => ({
            show: false,
            data: null,
            loading: false,
        }),
        [EDIT_SELECTED_INCIDENCE]: (s, action) => ({
            show: true,
            data: action.payload,
            loading: false,
        }),
        [INCIDENCE_FORM_TOOGLE_LOADING]: (state) => ({
            ...state,
            loading: !state.loading,
        }),
    },
    { show: false, data: null, loading: false }
);

// BUYTYPES
export const buyTypes = handleActions(
    {
        [SET_BUYTYPE_LIST]: (state, action) => action.payload,
        [ADD_NEW_BUYTYPE]: (state, action) => {
            const newState = {
                ...state,
                [action.payload.value]: action.payload,
            };
            return newState;
        },
        [UPDATE_BUYTYPE]: (state, action) => {
            const newState = {
                ...state,
                [action.payload.value]: action.payload,
            };
            return newState;
        },
        [DELETE_BUYTYPE]: (state, action) => {
            action.payload.forEach((number) => {
                delete state[number];
            });
            return { ...state };
        },
    },
    null
);

export const buyTypesForm = handleActions(
    {
        [OPEN_BUYTYPE_FORM]: (state) => ({
            show: !state.show,
            data: null,
            loading: false,
        }),
        [CLOSE_BUYTYPE_FORM]: () => ({
            show: false,
            data: null,
            loading: false,
        }),
        [EDIT_SELECTED_BUYTYPE]: (s, action) => ({
            show: true,
            data: action.payload,
            loading: false,
        }),
        [BUYTYPE_FORM_TOOGLE_LOADING]: (state) => ({
            ...state,
            loading: !state.loading,
        }),
    },
    { show: false, data: null, loading: false }
);

// BUYS
export const buys = handleActions(
    {
        [SET_BUY_LIST]: (state, action) => action.payload,
        [ADD_NEW_BUY]: (state, action) => {
            const newState = {
                ...state,
                [action.payload.value]: action.payload,
            };
            return newState;
        },
        [UPDATE_BUY]: (state, action) => {
            const newState = {
                ...state,
                [action.payload.value]: action.payload,
            };
            return newState;
        },
        [DELETE_BUY]: (state, action) => {
            action.payload.forEach((number) => {
                delete state[number];
            });
            return { ...state };
        },
    },
    null
);

export const buysForm = handleActions(
    {
        [OPEN_BUY_FORM]: (state) => ({
            show: !state.show,
            data: null,
            loading: false,
        }),
        [CLOSE_BUY_FORM]: () => ({
            show: false,
            data: null,
            loading: false,
        }),
        [EDIT_SELECTED_BUY]: (s, action) => ({
            show: true,
            data: action.payload,
            loading: false,
        }),
        [BUY_FORM_TOOGLE_LOADING]: (state) => ({
            ...state,
            loading: !state.loading,
        }),
    },
    { show: false, data: null, loading: false }
);
