import { axios } from "../connection/ConnectionHandler";
import { API } from "../constants/api";
import { keyBy } from "lodash";
import {
    ADD_NEW_ELEMENT,
    DELETE_ELEMENT,
    ELEMENT_FORM_TOOGLE_LOADING,
    SET_ELEMENT_LIST,
    SHOW_NOTIFICATION,
    UPDATE_ELEMENT,
} from "../constants";
import { formatElements } from "./settingsActionsUtils";

/* ELEMENT LIST */
export const fetchElements = () => {
    return async (dispatch) => {
        const response = await axios
            .get(API.elements.list)
            .then((response) => {
                const elements = formatElements(response.data);
                dispatch({
                    type: SET_ELEMENT_LIST,
                    payload: keyBy(elements, "_id"),
                });
                return response.status;
            })
            .catch((err) => err);
        return response;
    };
};

// ELEMENTS
export const createElement = (newElement) => {
    return async (dispatch) => {
        dispatch({ type: ELEMENT_FORM_TOOGLE_LOADING });
        const response = await axios
            .post(API.elements.create, newElement)
            .then((response) => {
                const element = formatElements(response.data);
                dispatch({ type: ADD_NEW_ELEMENT, payload: element });
                dispatch({ type: ELEMENT_FORM_TOOGLE_LOADING });
                dispatch({
                    type: SHOW_NOTIFICATION,
                    payload: {
                        show: true,
                        status: "success",
                        message: "Elemento creado con éxito.",
                    },
                });
                return response && response.status;
            })
            .catch((err) => {
                dispatch({ type: ELEMENT_FORM_TOOGLE_LOADING });
                return err;
            });
        return response;
    };
};

export const updateElement = (updatedElement) => {
    return async (dispatch) => {
        dispatch({ type: ELEMENT_FORM_TOOGLE_LOADING });
        const response = await axios
            .put(`${API.elements.edit}${updatedElement && updatedElement._id}`, updatedElement)
            .then((response) => {
                const element = formatElements(response.data);
                dispatch({ type: UPDATE_ELEMENT, payload: element });
                dispatch({ type: ELEMENT_FORM_TOOGLE_LOADING });
                dispatch({
                    type: SHOW_NOTIFICATION,
                    payload: {
                        show: true,
                        status: "info",
                        message: "Elemento actualizado.",
                    },
                });
                return response && response.status;
            })
            .catch((err) => {
                dispatch({ type: ELEMENT_FORM_TOOGLE_LOADING });
                return err;
            });
        return response;
    };
};

export const deleteElements = (Ids) => {
    return async (dispatch) => {
        dispatch({ type: ELEMENT_FORM_TOOGLE_LOADING });
        const response = await axios
            .delete(`${API.elements.delete}`, { data: Ids })
            .then((response) => {
                dispatch({ type: DELETE_ELEMENT, payload: Ids });
                dispatch({ type: ELEMENT_FORM_TOOGLE_LOADING });
                dispatch({
                    type: SHOW_NOTIFICATION,
                    payload: {
                        show: true,
                        status: "info",
                        message: "Elemento eliminado.",
                    },
                });
                return response && response.status;
            })
            .catch((err) => {
                dispatch({ type: ELEMENT_FORM_TOOGLE_LOADING });
                return err;
            });
        return response;
    };
};
