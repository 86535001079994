import { axios } from "../connection/ConnectionHandler";
import { API } from "../constants/api";
import { keyBy } from "lodash";
import {
    ADD_NEW_BUYTYPE,
    DELETE_BUYTYPE,
    BUYTYPE_FORM_TOOGLE_LOADING,
    SET_BUYTYPE_LIST,
    SHOW_NOTIFICATION,
    UPDATE_BUYTYPE,
} from "../constants";
import { formatBuyTypes } from "./settingsActionsUtils";

/* BUYTYPE LIST */
export const fetchBuyTypes = () => {
    return async (dispatch) => {
        const response = await axios
            .get(API.buyTypes.list)
            .then((response) => {
                const buyTypes = formatBuyTypes(response.data);
                dispatch({
                    type: SET_BUYTYPE_LIST,
                    payload: keyBy(buyTypes, "_id"),
                });
                return response.status;
            })
            .catch((err) => err);
        return response;
    };
};

// BUYTYPES
export const createBuyType = (newBuyType) => {
    return async (dispatch) => {
        dispatch({ type: BUYTYPE_FORM_TOOGLE_LOADING });
        const response = await axios
            .post(API.buyTypes.create, newBuyType)
            .then((response) => {
                const buyType = formatBuyTypes(response.data);
                dispatch({ type: ADD_NEW_BUYTYPE, payload: buyType });
                dispatch({ type: BUYTYPE_FORM_TOOGLE_LOADING });
                dispatch({
                    type: SHOW_NOTIFICATION,
                    payload: {
                        show: true,
                        status: "success",
                        message: "Tipo de compra creado con éxito.",
                    },
                });
                return response && response.status;
            })
            .catch((err) => {
                dispatch({ type: BUYTYPE_FORM_TOOGLE_LOADING });
                return err;
            });
        return response;
    };
};

export const updateBuyType = (updatedBuyType) => {
    return async (dispatch) => {
        dispatch({ type: BUYTYPE_FORM_TOOGLE_LOADING });
        const response = await axios
            .put(`${API.buyTypes.edit}${updatedBuyType && updatedBuyType._id}`, updatedBuyType)
            .then((response) => {
                const buyType = formatBuyTypes(response.data);
                dispatch({ type: UPDATE_BUYTYPE, payload: buyType });
                dispatch({ type: BUYTYPE_FORM_TOOGLE_LOADING });
                dispatch({
                    type: SHOW_NOTIFICATION,
                    payload: {
                        show: true,
                        status: "info",
                        message: "Tipo de compra actualizado.",
                    },
                });
                return response && response.status;
            })
            .catch((err) => {
                dispatch({ type: BUYTYPE_FORM_TOOGLE_LOADING });
                return err;
            });
        return response;
    };
};

export const deleteBuyTypes = (Ids) => {
    return async (dispatch) => {
        dispatch({ type: BUYTYPE_FORM_TOOGLE_LOADING });
        const response = await axios
            .delete(`${API.buyTypes.delete}`, { data: Ids })
            .then((response) => {
                dispatch({ type: DELETE_BUYTYPE, payload: Ids });
                dispatch({ type: BUYTYPE_FORM_TOOGLE_LOADING });
                dispatch({
                    type: SHOW_NOTIFICATION,
                    payload: {
                        show: true,
                        status: "info",
                        message: "Tipo de compra eliminado.",
                    },
                });
                return response && response.status;
            })
            .catch((err) => {
                dispatch({ type: BUYTYPE_FORM_TOOGLE_LOADING });
                return err;
            });
        return response;
    };
};
