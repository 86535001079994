import { combineReducers } from "redux";
import {
  authorization,
  profile,
  currentRoute,
  notifications,
  app,
  usersForm,
  users,
  alerts,
  alertsForm,
  currentTheme,
  staffs,
  staffsForm,
  elements,
  elementsForm,
  clothes,
  clothesForm,
  rents,
  rentsForm,
  typeClothes,
  typeClothesForm,
  personalClotheLists,
  personalClotheListsForm,
  documents,
  documentsForm,
  incidences,
  incidencesForm,
  buyTypes,
  buyTypesForm,
  buys,
  buysForm,
} from "./Reducers";

export default combineReducers({
  buys,
  buysForm,
  buyTypes,
  buyTypesForm,
  incidences,
  incidencesForm,
  documents,
  documentsForm,
  personalClotheLists,
  personalClotheListsForm,
  typeClothes,
  typeClothesForm,
  rents,
  rentsForm,
  clothes,
  clothesForm,
  elements,
  elementsForm,
  staffs,
  staffsForm,
  app,
  authorization,
  currentRoute,
  profile,
  notifications,
  usersForm,
  users,
  alerts,
  alertsForm,
  currentTheme,
});
