import { axios } from "../connection/ConnectionHandler";
import { API } from "../constants/api";
import { keyBy } from "lodash";
import {
    ADD_NEW_RENT,
    DELETE_RENT,
    RENT_FORM_TOOGLE_LOADING,
    SET_RENT_LIST,
    SHOW_NOTIFICATION,
    UPDATE_RENT,
} from "../constants";
import { formatRents } from "./settingsActionsUtils";

/* RENT LIST */
export const fetchRents = () => {
    return async (dispatch) => {
        const response = await axios
            .get(API.rents.list)
            .then((response) => {
                const rents = formatRents(response.data);
                dispatch({
                    type: SET_RENT_LIST,
                    payload: keyBy(rents, "_id"),
                });
                return response.status;
            })
            .catch((err) => err);
        return response;
    };
};

// RENTS
export const createRent = (newRent) => {
    return async (dispatch) => {
        dispatch({ type: RENT_FORM_TOOGLE_LOADING });
        const response = await axios
            .post(API.rents.create, newRent)
            .then((response) => {
                const rent = formatRents(response.data);
                dispatch({ type: ADD_NEW_RENT, payload: rent });
                dispatch({ type: RENT_FORM_TOOGLE_LOADING });
                dispatch({
                    type: SHOW_NOTIFICATION,
                    payload: {
                        show: true,
                        status: "success",
                        message: "Alquiler creado con éxito.",
                    },
                });
                return response && response.status;
            })
            .catch((err) => {
                dispatch({ type: RENT_FORM_TOOGLE_LOADING });
                return err;
            });
        return response;
    };
};

export const updateRent = (updatedRent) => {
    return async (dispatch) => {
        dispatch({ type: RENT_FORM_TOOGLE_LOADING });
        const response = await axios
            .put(`${API.rents.edit}${updatedRent && updatedRent._id}`, updatedRent)
            .then((response) => {
                const rent = formatRents(response.data);
                dispatch({ type: UPDATE_RENT, payload: rent });
                dispatch({ type: RENT_FORM_TOOGLE_LOADING });
                dispatch({
                    type: SHOW_NOTIFICATION,
                    payload: {
                        show: true,
                        status: "info",
                        message: "Alquiler actualizado.",
                    },
                });
                return response && response.status;
            })
            .catch((err) => {
                dispatch({ type: RENT_FORM_TOOGLE_LOADING });
                return err;
            });
        return response;
    };
};

export const deleteRents = (Ids) => {
    return async (dispatch) => {
        dispatch({ type: RENT_FORM_TOOGLE_LOADING });
        const response = await axios
            .delete(`${API.rents.delete}`, { data: Ids })
            .then((response) => {
                dispatch({ type: DELETE_RENT, payload: Ids });
                dispatch({ type: RENT_FORM_TOOGLE_LOADING });
                dispatch({
                    type: SHOW_NOTIFICATION,
                    payload: {
                        show: true,
                        status: "info",
                        message: "Alquiler eliminado.",
                    },
                });
                return response && response.status;
            })
            .catch((err) => {
                dispatch({ type: RENT_FORM_TOOGLE_LOADING });
                return err;
            });
        return response;
    };
};
