/* BASIC APP CONSTANTS */
export const AUTH = "AUTH";
export const LOGOUT = "LOGOUT";
export const APP_COOKIE_NAME = "APP_COOKIE_NAME";
export const USER_COOKIE_NAME = "USER_COOKIE_NAME";
export const PASS_COOKIE_NAME = "PASS_COOKIE_NAME";
export const USER_ID_COOKIE_NAME = "USER_ID_COOKIE_NAME";
export const SET_CURRENT_ROUTE = "SET_CURRENT_ROUTE";
export const SET_SIDE_MENU_STATE = "SET_SIDE_MENU_STATE";
export const SHOW_NOTIFICATION = "SHOW_NOTIFICATION";
export const CLOSE_NOTIFICATION = "CLOSE_NOTIFICATION";
export const SHOW_ACTION_MENU = "SHOW_ACTION_MENU";
export const HIDE_ACTION_MENU = "HIDE_ACTION_MENU";
export const SHOW_BOTTOM_TOOLBAR = "SHOW_BOTTOM_TOOLBAR";
export const HIDE_BOTTOM_TOOLBAR = "HIDE_BOTTOM_TOOLBAR";
export const SET_USER_LIST = "SET_USER_LIST";
export const EDIT_DISABLED_BUTTONS = "EDIT_DISABLED_BUTTONS";
export const SET_LOGS_LIST = "SET_LOGS_LIST";
export const THEME = "THEME";

/* <---- BASIC APP CONSTANTS ----> */

/* SOCKET CONSTANTS */
export const CONNECTED = "connected";
export const DISCONNECTED = "disconnected";
/* <---- SOCKET CONSTANTS ----> */

/* CONFIGURATION */
export const CONFIGURATION_FORM_TOOGLE_LOADING =
  "CONFIGURATION_FORM_TOOGLE_LOADING";
export const SET_CONFIGURATION = "SET_CONFIGURATION";
/* <---- CONFIGURATION ----> */

/* USER */
export const SET_PROFILE = "SET_PROFILE";
/* <---- USER ----> */

/* USERS */
export const ADD_NEW_USER = "ADD_NEW_USER";
export const UPDATE_USER = "UPDATE_USER";
export const DELETE_USER = "DELETE_USER";
export const OPEN_USERS_FORM = "OPEN_USERS_FORM";
export const CLOSE_USERS_FORM = "CLOSE_USERS_FORM";
export const EDIT_SELECTED_USER = "EDIT_SELECTED_USER";
export const USERS_FORM_TOOGLE_LOADING = "USERS_FORM_TOOGLE_LOADING";
/* <---- USERS ----> */

/* BUY */
export const SET_BUY_LIST = "SET_BUY_LIST";
export const ADD_NEW_BUY = "ADD_NEW_BUY";
export const UPDATE_BUY = "UPDATE_BUY";
export const DELETE_BUY = "DELETE_BUY";
export const OPEN_BUY_FORM = "OPEN_BUY_FORM";
export const CLOSE_BUY_FORM = "CLOSE_BUY_FORM";
export const EDIT_SELECTED_BUY = "EDIT_SELECTED_BUY";
export const BUY_FORM_TOOGLE_LOADING = "BUY_FORM_TOOGLE_LOADING";
/* <---- BUY ----> */

/* BUYTYPE */
export const SET_BUYTYPE_LIST = "SET_BUYTYPE_LIST";
export const ADD_NEW_BUYTYPE = "ADD_NEW_BUYTYPE";
export const UPDATE_BUYTYPE = "UPDATE_BUYTYPE";
export const DELETE_BUYTYPE = "DELETE_BUYTYPE";
export const OPEN_BUYTYPE_FORM = "OPEN_BUYTYPE_FORM";
export const CLOSE_BUYTYPE_FORM = "CLOSE_BUYTYPE_FORM";
export const EDIT_SELECTED_BUYTYPE = "EDIT_SELECTED_BUYTYPE";
export const BUYTYPE_FORM_TOOGLE_LOADING = "BUYTYPE_FORM_TOOGLE_LOADING";
/* <---- BUYTYPE ----> */

/* INCIDENCE */
export const SET_INCIDENCE_LIST = "SET_INCIDENCE_LIST";
export const ADD_NEW_INCIDENCE = "ADD_NEW_INCIDENCE";
export const UPDATE_INCIDENCE = "UPDATE_INCIDENCE";
export const DELETE_INCIDENCE = "DELETE_INCIDENCE";
export const OPEN_INCIDENCE_FORM = "OPEN_INCIDENCE_FORM";
export const CLOSE_INCIDENCE_FORM = "CLOSE_INCIDENCE_FORM";
export const EDIT_SELECTED_INCIDENCE = "EDIT_SELECTED_INCIDENCE";
export const INCIDENCE_FORM_TOOGLE_LOADING = "INCIDENCE_FORM_TOOGLE_LOADING";
/* <---- INCIDENCE ----> */

/* DOCUMENT */
export const SET_DOCUMENT_LIST = "SET_DOCUMENT_LIST";
export const ADD_NEW_DOCUMENT = "ADD_NEW_DOCUMENT";
export const UPDATE_DOCUMENT = "UPDATE_DOCUMENT";
export const DELETE_DOCUMENT = "DELETE_DOCUMENT";
export const OPEN_DOCUMENT_FORM = "OPEN_DOCUMENT_FORM";
export const CLOSE_DOCUMENT_FORM = "CLOSE_DOCUMENT_FORM";
export const EDIT_SELECTED_DOCUMENT = "EDIT_SELECTED_DOCUMENT";
export const DOCUMENT_FORM_TOOGLE_LOADING = "DOCUMENT_FORM_TOOGLE_LOADING";
/* <---- DOCUMENT ----> */

/* PERSONALCLOTHELIST */
export const SET_PERSONALCLOTHELIST_LIST = "SET_PERSONALCLOTHELIST_LIST";
export const ADD_NEW_PERSONALCLOTHELIST = "ADD_NEW_PERSONALCLOTHELIST";
export const UPDATE_PERSONALCLOTHELIST = "UPDATE_PERSONALCLOTHELIST";
export const DELETE_PERSONALCLOTHELIST = "DELETE_PERSONALCLOTHELIST";
export const OPEN_PERSONALCLOTHELIST_FORM = "OPEN_PERSONALCLOTHELIST_FORM";
export const CLOSE_PERSONALCLOTHELIST_FORM = "CLOSE_PERSONALCLOTHELIST_FORM";
export const EDIT_SELECTED_PERSONALCLOTHELIST = "EDIT_SELECTED_PERSONALCLOTHELIST";
export const PERSONALCLOTHELIST_FORM_TOOGLE_LOADING = "PERSONALCLOTHELIST_FORM_TOOGLE_LOADING";
/* <---- PERSONALCLOTHELIST ----> */

/* TYPECLOTHE */
export const SET_TYPECLOTHE_LIST = "SET_TYPECLOTHE_LIST";
export const ADD_NEW_TYPECLOTHE = "ADD_NEW_TYPECLOTHE";
export const UPDATE_TYPECLOTHE = "UPDATE_TYPECLOTHE";
export const DELETE_TYPECLOTHE = "DELETE_TYPECLOTHE";
export const OPEN_TYPECLOTHE_FORM = "OPEN_TYPECLOTHE_FORM";
export const CLOSE_TYPECLOTHE_FORM = "CLOSE_TYPECLOTHE_FORM";
export const EDIT_SELECTED_TYPECLOTHE = "EDIT_SELECTED_TYPECLOTHE";
export const TYPECLOTHE_FORM_TOOGLE_LOADING = "TYPECLOTHE_FORM_TOOGLE_LOADING";
/* <---- TYPECLOTHE ----> */

/* RENT */
export const SET_RENT_LIST = "SET_RENT_LIST";
export const ADD_NEW_RENT = "ADD_NEW_RENT";
export const UPDATE_RENT = "UPDATE_RENT";
export const DELETE_RENT = "DELETE_RENT";
export const OPEN_RENT_FORM = "OPEN_RENT_FORM";
export const CLOSE_RENT_FORM = "CLOSE_RENT_FORM";
export const EDIT_SELECTED_RENT = "EDIT_SELECTED_RENT";
export const RENT_FORM_TOOGLE_LOADING = "RENT_FORM_TOOGLE_LOADING";
/* <---- RENT ----> */

/* CLOTHE */
export const SET_CLOTHE_LIST = "SET_CLOTHE_LIST";
export const ADD_NEW_CLOTHE = "ADD_NEW_CLOTHE";
export const UPDATE_CLOTHE = "UPDATE_CLOTHE";
export const DELETE_CLOTHE = "DELETE_CLOTHE";
export const OPEN_CLOTHE_FORM = "OPEN_CLOTHE_FORM";
export const CLOSE_CLOTHE_FORM = "CLOSE_CLOTHE_FORM";
export const EDIT_SELECTED_CLOTHE = "EDIT_SELECTED_CLOTHE";
export const CLOTHE_FORM_TOOGLE_LOADING = "CLOTHE_FORM_TOOGLE_LOADING";
/* <---- CLOTHE ----> */

/* ELEMENT */
export const SET_ELEMENT_LIST = "SET_ELEMENT_LIST";
export const ADD_NEW_ELEMENT = "ADD_NEW_ELEMENT";
export const UPDATE_ELEMENT = "UPDATE_ELEMENT";
export const DELETE_ELEMENT = "DELETE_ELEMENT";
export const OPEN_ELEMENT_FORM = "OPEN_ELEMENT_FORM";
export const CLOSE_ELEMENT_FORM = "CLOSE_ELEMENT_FORM";
export const EDIT_SELECTED_ELEMENT = "EDIT_SELECTED_ELEMENT";
export const ELEMENT_FORM_TOOGLE_LOADING = "ELEMENT_FORM_TOOGLE_LOADING";
/* <---- ELEMENT ----> */

/* STAFF */
export const SET_STAFF_LIST = "SET_STAFF_LIST";
export const ADD_NEW_STAFF = "ADD_NEW_STAFF";
export const UPDATE_STAFF = "UPDATE_STAFF";
export const DELETE_STAFF = "DELETE_STAFF";
export const OPEN_STAFF_FORM = "OPEN_STAFF_FORM";
export const CLOSE_STAFF_FORM = "CLOSE_STAFF_FORM";
export const EDIT_SELECTED_STAFF = "EDIT_SELECTED_STAFF";
export const STAFF_FORM_TOOGLE_LOADING = "STAFF_FORM_TOOGLE_LOADING";
/* <---- STAFF ----> */

/* ROUTES */
export const SET_ALL_ROUTES = "SET_ALL_ROUTES";
export const UPDATE_ROUTE = "UPDATE_ROUTE";
/* <---- ROUTES ----> */

/* ALERT */
export const SET_ALERT_LIST = "SET_ALERT_LIST";
export const ADD_NEW_ALERT = "ADD_NEW_ALERT";
export const UPDATE_ALERT = "UPDATE_ALERT";
export const DELETE_ALERT = "DELETE_ALERT";
export const OPEN_ALERT_FORM = "OPEN_ALERT_FORM";
export const CLOSE_ALERT_FORM = "CLOSE_ALERT_FORM";
export const EDIT_SELECTED_ALERT = "EDIT_SELECTED_ALERT";
export const ALERT_FORM_TOOGLE_LOADING = "ALERT_FORM_TOOGLE_LOADING";
/* <---- ALERT ----> */
